<template>
    <div>
      <div class="header pb-6 d-flex align-items-center">
        <!-- Mask -->
        <span class="mask bg-white opacity-8"></span>
        <!-- Header container -->
        <b-container fluid class="d-flex align-items-center">
          <b-row>
            <b-col lg="12">
            </b-col>
          </b-row>
        </b-container>
      </div>
  
      <b-container fluid class="mt--6">
        <b-row>
          <b-col sm="12">
            <div>
              <my-hackathon-profile> </my-hackathon-profile>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  import MyHackathonProfile from "@/views/Components/Hackathon/MyHackathonProfile.vue";
  
  export default {
    name: "HackathonProfile",
    components: {
      MyHackathonProfile,
    },
  };
  </script>
  
  <style>

  </style>
  